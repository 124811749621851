<template>
	<div>
		<no-ssr>
		<div class="sales-wrapper-white">
			<div class="sales-header">
				<div class="sales-header-text">
					<h1>Skip The Lines</h1>
					<p>Fine Grounds connects you to your local coffee shops in the easiest possible way. Simply enter your current location and find all the coffee shops and stands around you who are taking orders!</p>
					<router-link to="/browse">Find Your Perfect Cup<span class="fal fa-mug"></span></router-link><br><br>
					<a v-if="platform === 'web'" href="https://apps.apple.com/us/app/fine-grounds/id1554716079" class="store-link" target="_blank"><span class="fab fa-app-store"></span>Download on iOS</a>
					<a v-if="platform === 'web'" href="https://play.google.com/store/apps/details?id=app.finegrounds.app" class="store-link" target="_blank"><span class="fab fa-google-play"></span>Download on Google Play</a>
				</div>
				<div class="sales-header-video"><img src="/images/photos/swipealt.jpg" alt=""></div>
			</div>
		</div>
		</no-ssr>
		<div class="sales-wrapper-grey">
			<div class="sales-grid-wrapper">
				<h3>Your Daily Cup Made More Convenient!</h3>
				<div class="sales-grid-container">
					<router-link to="/browse" class="sales-grid-block">
						<h5>Browse</h5>
						<img src="/images/photos/girlphone1.jpg" alt="">
						<p>Look through all the local coffee shops and stands who are ready and eager to serve you your perfect cup.</p>
					</router-link>
					<router-link to="/browse" class="sales-grid-block">
						<h5>Order</h5>
						<img src="/images/photos/guyphone.jpg" alt="">
						<p>Add everything you want to your cart, including customizations, because we understand the <b>need</b> for that extra shot.</p>
					</router-link>
					<router-link to="/browse" class="sales-grid-block">
						<h5>Pick Up</h5>
						<img src="/images/photos/girlphone2.jpg" alt="">
						<p>Place the order by using Apple Pay or Google Pay and head to the shop to pick up your order!</p>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {

	name: 'HomePage',

	data() {
		return {
			loaded: false,
		};
	},

	computed: mapGetters(['platform']),

	created() {
		// if (this.platform && this.platform !== 'web') {
		// 	return this.$router.replace('/browse/list');
		// }
		this.loaded = true;
	},

};

</script>
